@font-face {
  font-family: roboto_slabbold;
  src: url("RobotoSlab-Bold-webfont.6619ad5c.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: roboto_light;
  src: url("RobotoSlab-Light-webfont.15f5d5c2.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: roboto_regular;
  src: url("RobotoSlab-Regular-webfont.b1e6932f.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: roboto_thin;
  src: url("RobotoSlab-Thin-webfont.8c266e93.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
/*# sourceMappingURL=index.e4236c2a.css.map */
