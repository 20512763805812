@font-face {
    font-family: 'roboto_slabbold';
    src: url('RobotoSlab-Bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'roboto_light';
    src: url('RobotoSlab-Light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'roboto_regular';
    src: url('RobotoSlab-Regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'roboto_thin';
    src: url('RobotoSlab-Thin-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}